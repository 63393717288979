import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Box from "common/components/Box";
import Container from "@mui/material/Container";
import { useQueryParams, StringParam } from 'use-query-params';

const ThankYouPage = ({ location }) => {

  const [query, setQuery] = useQueryParams({
    name: StringParam,
    source: StringParam
  })
  return (
    <Layout showContactMap={query.source === 'website-get-started' ? false : true} 
      showFreeConsult={query.source === 'website-get-started' ? false : true} 
      location={location.pathname}>
          <Seo title="Webinar Registration Thank you" />
          <Container minWidth="400px" sx={{
            marginTop: "100px",
            padding: "40px",
            border: "1px solid #efefef",
            borderRadius: "10px",
            marginBottom: "20px",
            minHeight: "600px"
          }}>
            
              <Box>
                <h3>
                  Awesome! You have taken the first step towards claiming your
                  Employee Retention Credit. 
                </h3>
                <p>
                  We look forward to seeing you at the webinar on April 6th, 2022 @ 10:00 am EST.
                </p>
                <h4>
                  Thank you for giving us the opportunity to be of service to you. <br /><br />2020 and 2021 have been difficult years especially
                  for small businesses. The ERC was designed to provide you the small business owner with a valuable tax incentive that 
                  will make things easier over the next few months.
                </h4>
              </Box>

          </Container>
    </Layout>
  );
};

export default ThankYouPage;
